import { gql } from '@apollo/client'
import { useToggle } from '@gameonsports/components/cjs/_hooks/useToggle'
import Button from '@gameonsports/components/cjs/Button'
import DateFormat from '@gameonsports/components/cjs/DateFormat'
import Icon from '@gameonsports/components/cjs/Icon'
import Loader from '@gameonsports/components/cjs/Loader'
import Notification from '@gameonsports/components/cjs/Notification'
import Pill from '@gameonsports/components/cjs/Pill'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { RouteComponentProps, useParams } from '@reach/router'
import { zonedTimeToUtc } from 'date-fns-tz'
import format from 'date-fns/format'
import dateIsAfter from 'date-fns/isAfter'
import dateIsBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import toDate from 'date-fns/toDate'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import FullWidthBlock from '../../components/FullWidthBlock'
import OrganisationPageWrapper from '../../components/OrganisationPageWrapper'
import OrgLogoName from '../../components/OrgLogoName'
import { Link } from '../../components/Router'
import SectionContainer from '../../components/SectionContainer'
import {
  OrganisationRegistrationsDiscoverOrganisation,
  OrganisationRegistrationsEndDate,
  OrganisationRegistrationsOrganisationRegistrations,
  OrganisationRegistrationsRegistrationUserTypes,
  OrganisationRegistrationsStartDate,
  OrganisationRegistrations_Logo,
  OrganisationType,
  SeasonRegistrationType,
  TenantContactRolesConfigurationFragmentDoc,
  useOrganisationRegistrationsQuery,
} from '../../generated/graphql'
import { useAnalytics } from '../../hooks/useAnalytics'
import { setOrganisationContacts } from '../../utils/organisation'
import { media } from '../../utils/styled-components-utils'

export const ORGANISATION_REGISTRATIONS = gql`
  query organisationRegistrations($code: ID!, $codeString: String!) {
    discoverOrganisation(code: $codeString) {
      id
      type
      name
      email
      contactNumber
      websiteUrl
      address {
        id
        line1
        suburb
        postcode
        state
        country
      }
      logo {
        sizes {
          url
          dimensions {
            width
            height
          }
        }
      }
      contacts {
        id
        firstName
        lastName
        position
        email
        phone
      }
      shopVisible
    }
    organisationRegistrations(code: $code) {
      id
      type
      registrationCode
      startDate {
        date
        time
        timezone
      }
      endDate {
        date
        time
        timezone
      }
      season {
        ... on ProgramSeason {
          id
          name
          startDate
          endDate
          program {
            id
            name
            description
            alias
          }
        }
        ... on DiscoverSeason {
          id
          name
          startDate
          endDate
          competition {
            id
            name
          }
        }
      }
      hostOrganisation {
        id
        name
        type
        logo {
          sizes {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
      registrationUserTypes {
        name
        displayName
        value
      }
    }
    tenantConfiguration {
      label
      sport {
        name
      }
      ...TenantContactRolesConfiguration
    }
  }
  ${TenantContactRolesConfigurationFragmentDoc}
`

const Box = styled.section`
  background-color: ${props => props.theme.white400};
  border: solid 1px ${props => props.theme.grey400};
  border-radius: 3px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
`

const RegistrationHeader = styled(Text).attrs({
  as: 'h3',
  color: 'blackberry400',
  weight: '700',
  textTransform: 'uppercase',
  size: '14',
})`
  background-color: ${props => props.theme.blackberry100};
  padding: 1rem;
  margin: 0;

  ${media.tablet`
    padding: 1rem 2.625rem;
  `}
`

const RegistrationBody = styled.div`
  padding: 1rem;

  ${media.tablet`
    padding: 1.875rem 2.625rem 2.375rem;
  `}
`

const AssociationLogoName = styled(OrgLogoName).attrs({
  weight: '700',
})`
  padding: 0;
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 0;
  }
`

const EventContainer = styled.div`
  & + & {
    margin-top: 1.5rem;
  }
`

const EventName = styled(Text).attrs({
  size: '18',
  weight: '700',
  as: 'h2',
})`
  margin: 0;
`

const SeasonSection = styled.section`
  & + & {
    margin-top: 1rem;
  }
`

const SeasonDetails = styled(Text).attrs({
  color: 'darkGrey400',
  weight: '500',
  as: 'p',
})`
  margin: 0.5rem 0 0;
`

const NoRegistrations = styled(Box)`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;

  ${media.tablet`
    grid-gap: 2.125rem;
    padding: 3rem;
  `}
`

const SelectButton = styled(Button)`
  display: none;

  ${media.tablet`
    display: block;
  `}
`

const Registration = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border: 1px solid ${props => props.theme.bodyBackground};
  text-decoration: none;
  color: ${props =>
    props.disabled ? props.theme.darkGrey400 : props.theme.bodyCopy};
  transition: background-color 150ms ${props => props.theme.easeInOutCirc};
  margin: 1rem 0;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .pill,
  button {
    grid-column: span 2;
  }

  svg {
    display: block;
    fill: currentColor;
  }

  ${props =>
    props.disabled
      ? css`
          background-color: ${props.theme.lightGrey400};
        `
      : css`
          &:hover,
          &:focus {
            background-color: ${props.theme.blackberry100};

            ${SelectButton} {
              background-color: ${props.theme.buttonTertiary};
              color: ${props.theme.white400};
            }
          }
        `}

  ${media.tablet`
    grid-template-columns: 2rem 2fr 1fr 6rem;

    .pill,
    button {
      grid-column: initial;
    }
  `}
`

interface RegistrationType {
  id: string
  type: string
  code: string
  startDate: OrganisationRegistrationsStartDate
  endDate: OrganisationRegistrationsEndDate
  registrationUserTypes: OrganisationRegistrationsRegistrationUserTypes[]
}

interface Season {
  id: string
  startDate: string
  endDate: string
  name: string
  registrations: RegistrationType[]
}

interface ActivityDetails {
  id: string
  name: string
  description?: string
  seasons: Season[]
}

interface HostOrganisation {
  id: string
  name: string
  type: OrganisationType
  logo?: OrganisationRegistrations_Logo
  activityDetails: ActivityDetails[]
}

const getIconForRegistrationType = (type: string) => {
  if (type === SeasonRegistrationType.SocialTeamToSeason) {
    return 'team'
  }
  if (type === SeasonRegistrationType.ParticipantProgram) {
    return 'player-profile-outline'
  }
  return 'player-coach-manager'
}

/**
 * Groups a list of given registrations by:
 * [HostOrganisation] -> [ActivityDetails] -> [Season] -> [Registration]
 *
 * @param registrations a list of registrations belonging to an organisation
 */
const groupRegistrations = (
  registrations: OrganisationRegistrationsOrganisationRegistrations[],
) =>
  registrations.reduce((acc: HostOrganisation[], cur) => {
    const hostIndex = acc.findIndex(host => host.id === cur.hostOrganisation.id)

    // Add registration to existing host
    if (hostIndex !== -1) {
      const activityIndex = acc[hostIndex].activityDetails.findIndex(
        activity =>
          activity.id ===
          (cur.season.__typename === 'ProgramSeason'
            ? cur.season.program.id
            : cur.season.__typename === 'DiscoverSeason'
            ? cur.season.competition.id
            : ''),
      )

      // Add registration to existing activity
      if (activityIndex !== -1) {
        const seasonIndex = acc[hostIndex].activityDetails[
          activityIndex
        ].seasons.findIndex(season => season.id === cur.season.id)

        // Add registration to existing season
        if (seasonIndex !== -1) {
          return [
            ...acc.slice(0, hostIndex),
            {
              ...acc[hostIndex],
              activityDetails: [
                ...acc[hostIndex].activityDetails.slice(0, activityIndex),
                {
                  ...acc[hostIndex].activityDetails[activityIndex],
                  seasons: [
                    ...acc[hostIndex].activityDetails[
                      activityIndex
                    ].seasons.slice(0, seasonIndex),
                    {
                      ...acc[hostIndex].activityDetails[activityIndex].seasons[
                        seasonIndex
                      ],
                      registrations: [
                        ...acc[hostIndex].activityDetails[activityIndex]
                          .seasons[seasonIndex].registrations,
                        {
                          id: cur.id,
                          type: cur.type,
                          code: cur.registrationCode,
                          startDate: cur.startDate,
                          endDate: cur.endDate,
                          registrationUserTypes: cur.registrationUserTypes,
                        },
                      ],
                    },
                    ...acc[hostIndex].activityDetails[
                      activityIndex
                    ].seasons.slice(seasonIndex + 1),
                  ],
                },
                ...acc[hostIndex].activityDetails.slice(activityIndex + 1),
              ],
            },
            ...acc.slice(hostIndex + 1),
          ]
        }

        // Add registration to new season
        return [
          ...acc.slice(0, hostIndex),
          {
            ...acc[hostIndex],
            activityDetails: [
              ...acc[hostIndex].activityDetails.slice(0, activityIndex),
              {
                ...acc[hostIndex].activityDetails[activityIndex],
                seasons: [
                  ...acc[hostIndex].activityDetails[activityIndex].seasons,
                  {
                    id: cur.season.id,
                    startDate: cur.season.startDate,
                    endDate: cur.season.endDate,
                    name: cur.season.name,
                    registrations: [
                      {
                        id: cur.id,
                        type: cur.type,
                        code: cur.registrationCode,
                        startDate: cur.startDate,
                        endDate: cur.endDate,
                        registrationUserTypes: cur.registrationUserTypes,
                      },
                    ],
                  },
                ],
              },
              ...acc[hostIndex].activityDetails.slice(activityIndex + 1),
            ],
          },
          ...acc.slice(hostIndex + 1),
        ]
      }

      // Add registration to new activity
      return [
        ...acc.slice(0, hostIndex),
        {
          ...acc[hostIndex],
          activityDetails: [
            ...acc[hostIndex].activityDetails,
            {
              id:
                cur.season.__typename === 'ProgramSeason'
                  ? cur.season.program.id
                  : cur.season.__typename === 'DiscoverSeason'
                  ? cur.season.competition.id
                  : '',
              name:
                cur.season.__typename === 'ProgramSeason'
                  ? [cur.season.program.name, cur.season.program.alias]
                      .filter(Boolean)
                      .join(' - ')
                  : cur.season.__typename === 'DiscoverSeason'
                  ? cur.season.competition.name
                  : '',
              description:
                cur.season.__typename === 'ProgramSeason'
                  ? cur.season.program.description
                  : undefined,
              seasons: [
                {
                  id: cur.season.id,
                  startDate: cur.season.startDate,
                  endDate: cur.season.endDate,
                  name: cur.season.name,
                  registrations: [
                    {
                      id: cur.id,
                      type: cur.type,
                      code: cur.registrationCode,
                      startDate: cur.startDate,
                      endDate: cur.endDate,
                      registrationUserTypes: cur.registrationUserTypes,
                    },
                  ],
                },
              ],
            },
          ],
        },
        ...acc.slice(hostIndex + 1),
      ]
    }

    // Add registration to new host
    return [
      ...acc,
      {
        id: cur.hostOrganisation.id,
        name: cur.hostOrganisation.name,
        type: cur.hostOrganisation.type,
        logo: cur.hostOrganisation.logo ?? undefined,
        activityDetails: [
          {
            id:
              cur.season.__typename === 'ProgramSeason'
                ? cur.season.program.id
                : cur.season.__typename === 'DiscoverSeason'
                ? cur.season.competition.id
                : '',
            name:
              cur.season.__typename === 'ProgramSeason'
                ? [cur.season.program.name, cur.season.program.alias]
                    .filter(Boolean)
                    .join(' - ')
                : cur.season.__typename === 'DiscoverSeason'
                ? cur.season.competition.name
                : '',
            description:
              cur.season.__typename === 'ProgramSeason'
                ? cur.season.program.description
                : undefined,
            seasons: [
              {
                id: cur.season.id,
                startDate: cur.season.startDate,
                endDate: cur.season.endDate,
                name: cur.season.name,
                registrations: [
                  {
                    id: cur.id,
                    type: cur.type,
                    code: cur.registrationCode,
                    startDate: cur.startDate,
                    endDate: cur.endDate,
                    registrationUserTypes: cur.registrationUserTypes,
                  },
                ],
              },
            ],
          },
        ],
      },
    ]
  }, [])

const EventDescriptionContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
`

const EventDescriptionText = styled(Text).attrs({
  color: 'darkGrey400',
  as: 'p',
})<{ readMore: boolean }>`
  margin: 0;

  ${props =>
    !props.readMore &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`

const EventDescriptionButton = styled(Text).attrs({
  as: 'button',
})`
  flex-shrink: 0;
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 0.25rem;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
`

const EventDescription: React.FC = ({ children }) => {
  const [readMore, toggleReadMore] = useToggle(false)
  const [showToggle, setShowToggle] = useState(false)
  const textEl = useRef<HTMLSpanElement>(null)

  const changeWindowSize = () => {
    const showToggle =
      readMore ||
      (textEl.current
        ? textEl.current.offsetWidth < textEl.current.scrollWidth
        : false)

    setShowToggle(showToggle)
  }

  React.useEffect(() => {
    changeWindowSize()
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readMore])

  return (
    <EventDescriptionContainer>
      <EventDescriptionText readMore={showToggle && readMore} ref={textEl}>
        {children}
        {showToggle && readMore && (
          <EventDescriptionButton onClick={toggleReadMore}>
            Read Less
          </EventDescriptionButton>
        )}
      </EventDescriptionText>
      {showToggle && !readMore && (
        <EventDescriptionButton onClick={toggleReadMore}>
          Read More
        </EventDescriptionButton>
      )}
    </EventDescriptionContainer>
  )
}

const ActivityRegistrations: React.FC<{
  name: string
  organisation?: OrganisationRegistrationsDiscoverOrganisation
  hostOrganisations: HostOrganisation[]
  tenant?: string
}> = ({ name, organisation, hostOrganisations, tenant }) => {
  const trackEvent = useAnalytics()
  return (
    <Box data-testid={`${name.toLowerCase()}-container`}>
      <RegistrationHeader>{name}</RegistrationHeader>
      <RegistrationBody>
        {hostOrganisations.map(host => (
          <React.Fragment key={host.id}>
            {organisation &&
              organisation.type === OrganisationType.Club &&
              host.type === OrganisationType.Association && (
                <AssociationLogoName name={host.name} logo={host.logo} />
              )}
            {host.activityDetails.map(activity => {
              return (
                <EventContainer key={activity.id}>
                  <EventName>{activity.name}</EventName>
                  {activity.description && (
                    <EventDescription>{activity.description}</EventDescription>
                  )}
                  {activity.seasons.map(season => {
                    return (
                      <SeasonSection key={season.id}>
                        <SeasonDetails>
                          {season.name}{' '}
                          <Text color="grey400" size="18">
                            &nbsp;&bull;&nbsp;
                          </Text>{' '}
                          <DateFormat date={season.startDate} /> -{' '}
                          <DateFormat date={season.endDate} />
                        </SeasonDetails>
                        {season.registrations.map(registration => {
                          const isTeamReg =
                            registration.type ===
                            SeasonRegistrationType.SocialTeamToSeason
                          const status = dateIsBefore(
                            toDate(new Date()),
                            zonedTimeToUtc(
                              `${registration.startDate.date}T${registration.startDate.time}`,
                              registration.startDate.timezone,
                            ),
                          )
                            ? 'upcoming'
                            : dateIsAfter(
                                toDate(new Date()),
                                zonedTimeToUtc(
                                  `${registration.endDate.date}T${registration.endDate.time}`,
                                  registration.endDate.timezone,
                                ),
                              )
                            ? 'completed'
                            : 'active'

                          // transforms a list of registration user types to a friendly string
                          // i.e. [PLAYER, COACH, TEAM_MANAGER] => "Player, Coach or Team Manager"
                          const registrationUserTypes =
                            registration.registrationUserTypes.length > 1
                              ? registration.registrationUserTypes
                                  .slice(0, -1)
                                  .map(
                                    userType =>
                                      userType.displayName ?? userType.name,
                                  )
                                  .join(', ') +
                                ' or ' +
                                registration.registrationUserTypes
                                  .slice(-1)
                                  .map(
                                    userType =>
                                      userType.displayName ?? userType.name,
                                  )
                                  .join()
                              : registration.registrationUserTypes
                                  .map(
                                    userType =>
                                      userType.displayName ?? userType.name,
                                  )
                                  .join()
                          return (
                            <Registration
                              key={registration.id}
                              disabled={
                                status === 'completed' || status === 'upcoming'
                              }
                              // Will get overridden if status is active
                              to=""
                              {...(status === 'active' && {
                                as: Link,
                                to: `/${tenant}/register/${registration.code}`,
                                'data-testid': `registration-${registration.id}-link`,
                              })}
                            >
                              <Icon
                                name={getIconForRegistrationType(
                                  registration.type,
                                )}
                                size="28"
                              />
                              <Text size="16" weight="600">
                                {isTeamReg
                                  ? 'Register a Team'
                                  : `Register a ${registrationUserTypes}`}
                              </Text>
                              <Pill
                                variant={
                                  status === 'upcoming'
                                    ? 'upcoming'
                                    : status === 'completed'
                                    ? 'complete'
                                    : 'active'
                                }
                                text={
                                  status === 'upcoming'
                                    ? `Opens on ${format(
                                        parseISO(registration.startDate.date),
                                        'dd MMM yyyy',
                                      )}`
                                    : status === 'completed'
                                    ? `Closed ${format(
                                        parseISO(registration.endDate.date),
                                        'dd MMM yyyy',
                                      )}`
                                    : `Open until ${format(
                                        parseISO(registration.endDate.date),
                                        'dd MMM yyyy',
                                      )}`
                                }
                                className="pill"
                              />
                              {status === 'active' && (
                                <SelectButton
                                  variant="tertiary"
                                  halo
                                  icon="right-arrow-strong"
                                  iconPosition="right"
                                  iconSize="12"
                                  size="small"
                                  onClick={() => {
                                    trackEvent('orgpage:register_select_click')
                                  }}
                                >
                                  Select
                                </SelectButton>
                              )}
                            </Registration>
                          )
                        })}
                      </SeasonSection>
                    )
                  })}
                </EventContainer>
              )
            })}
          </React.Fragment>
        ))}
      </RegistrationBody>
    </Box>
  )
}

const DiscoverRegistrations = (_: RouteComponentProps) => {
  const trackEvent = useAnalytics()
  const { tenant, organisationId } = useParams<{
    tenant: string
    organisationId: string
  }>()

  const { loading, error, data } = useOrganisationRegistrationsQuery({
    variables: {
      code: String(organisationId),
      codeString: String(organisationId),
    },
    fetchPolicy: 'no-cache',
    onError: () => null,
  })

  const sport = data?.tenantConfiguration?.sport?.name.toLowerCase() ?? ''
  const { t } = useTranslation(sport)
  const sportName = t('NAME').toLowerCase()

  useEffect(() => {
    trackEvent('orgpage:register_view')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading || !sportName) {
    return <Loader />
  }

  if (
    error ||
    !data?.discoverOrganisation ||
    !data?.organisationRegistrations
  ) {
    return (
      <Notification variant="error">
        There was an error loading the organisation's registrations.
      </Notification>
    )
  }

  const hasRegistrations = data.organisationRegistrations.length > 0
  const isClub = data.discoverOrganisation.type === OrganisationType.Club

  const programRegistrations = data.organisationRegistrations.filter(
    registration => registration.season.__typename === 'ProgramSeason',
  )
  const competitionRegistrations = data.organisationRegistrations.filter(
    registration => registration.season.__typename === 'DiscoverSeason',
  )

  const groupedProgramRegistrations = groupRegistrations(programRegistrations)
  const groupedCompetitionRegistrations = groupRegistrations(
    competitionRegistrations,
  )

  const organisation = setOrganisationContacts(
    data.discoverOrganisation,
    data.tenantConfiguration?.contactRoles ?? [],
  )

  return (
    <OrganisationPageWrapper
      tenant={String(tenant)}
      organisation={organisation}
    >
      <Helmet
        title={`Register to ${data.discoverOrganisation.name}`}
        meta={[
          {
            name: 'description',
            content: `Join ${data.discoverOrganisation.name} through PlayHQ. Register for ${sportName} competitions and programs, and start your season with ${data.discoverOrganisation.name}.`,
          },
        ]}
      />

      <FullWidthBlock>
        <SectionContainer noXPadding>
          {!hasRegistrations && (
            <NoRegistrations>
              <Text size="20" weight="700">
                No registrations available.
              </Text>
              <Notification variant="empty">
                The {isClub ? 'club' : 'association'} is not currently taking
                online registrations. Please contact the{' '}
                {isClub ? 'club' : 'association'} for more information.
              </Notification>
            </NoRegistrations>
          )}
          {groupedProgramRegistrations.length > 0 && (
            <ActivityRegistrations
              name="Programs"
              hostOrganisations={groupedProgramRegistrations}
              tenant={tenant}
            />
          )}
          {groupedCompetitionRegistrations.length > 0 && (
            <ActivityRegistrations
              name="Competitions"
              organisation={data.discoverOrganisation}
              hostOrganisations={groupedCompetitionRegistrations}
              tenant={tenant}
            />
          )}
        </SectionContainer>
      </FullWidthBlock>
    </OrganisationPageWrapper>
  )
}

export default DiscoverRegistrations
